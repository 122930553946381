const checkPhone = (rule, value, callback) => {
  const reg = /^1[345789]\d{9}$/
  if (!reg.test(value)) {
    callback(new Error('请输入11位手机号'))
  } else {
    callback()
  }
}
const validatePass = (rule, value, callback) => {
  // 必须包含⼤⼩写字母、数字、特殊字符长度再9-16位之间
  var regex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{9,16}')
  if (value === '') {
    callback(new Error('请输⼊密码'))
  } else if (value.length < 8) {
    callback(new Error('密码最少8位'))
  } else if (!regex.test(value)) {
    callback(new Error('密码必须同时包含字母、数字和特殊字符其中三项且⾄少8位'))
  } else {
    callback()
  }
}
export { checkPhone, validatePass }
